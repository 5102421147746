import React, { useState } from 'react';
import { Helmet } from 'react-helmet-async';

// locales
import { useTranslation } from 'react-i18next';
import { translations } from 'locales/i18n';

// styles
import { Wrapper, Title } from './styles';

import ResetPasswordByPhoneForm from './ResetPasswordByPhoneForm';
import ResetPasswordCodeConfirmationForm from './ResetPasswordCodeConfirmationForm';

export function ResetPasswordByPhonePage() {
  const [success, setSuccess] = useState(false);

  // locales hook
  const { t } = useTranslation();

  return (
    <>
      <Helmet>
        <title>{t(translations.resetPasswordByPhonePage.seo.title)}</title>
        <meta
          name="description"
          content={t(translations.resetPasswordByPhonePage.seo.description)}
        />
      </Helmet>

      <Wrapper>
        <Title>{t(translations.resetPasswordByPhonePage.title)}</Title>

        {!success ? (
          <ResetPasswordByPhoneForm
            loading={false}
            setSuccess={() => setSuccess(true)}
            submit={() => setSuccess(true)}
          />
        ) : (
          <ResetPasswordCodeConfirmationForm
            loading={false}
            setSuccess={() => setSuccess(true)}
            submit={() => setSuccess(true)}
          />
        )}
      </Wrapper>
    </>
  );
}
