import React, { useState, useRef, useEffect } from 'react';

// locales
import { useTranslation } from 'react-i18next';
import { translations } from 'locales/i18n';

// styles
import {
  ConfirmMessage,
  StyledInput,
  TriggerLink,
  TriggerResendCodeWrapper,
  TriggerResendCodeItem,
  VarificationCodeInputWrapper,
  Title,
} from '../styles';
import { useHistory, useLocation } from 'react-router-dom';
import { RestorePasswordBySmsFormProps } from './types';
import { $apiClient } from '../../../../utils/request';
import ErrorText from '../../../../atoms/ErrorText';
import { actions as resetPasswordActions } from '../../../../store/resetPasswordByEmail/slice';
import { actions as resetPasswordPhoneActions } from '../../../../store/resetPasswordByPhone/slice';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectDone,
  selectLoading as selectLoadingEmail,
} from '../../../../store/resetPasswordByEmail/selectors';
import { selectLoading as selectLoadingPhone } from '../../../../store/resetPasswordByPhone/selectors';
import LoadingIndicator from '../../../../atoms/LoadingIndicator';
import { TriggerBackPreviousPage } from '../../../ResetPassword/ResetPasswordByEmailPage/styles';

// result login form
export const VerificationRestorePasswordBySmsForm = ({
  currentContact,
  previousStep,
  newUser,
  closePopupProp,
}: RestorePasswordBySmsFormProps) => {
  // locales hook
  const { t } = useTranslation();

  const [apiErrors, setApiErrors] = useState('');

  // dispatch
  const dispatch = useDispatch();

  const resendSmsCode = payload => {
    dispatch(resetPasswordActions.fetchTrigger(payload));
  };
  const resendSmsCodeToPhone = payload => {
    dispatch(resetPasswordPhoneActions.fetchTrigger(payload));
  };

  // selectors
  const isLoadingEmail = useSelector(selectLoadingEmail);
  const isLoadingPhone = useSelector(selectLoadingPhone);

  const regexEmail = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})$/;
  const validateSmsCodeEndpoint = 'api/v1/validate-sms-code';

  console.log('currentContact', currentContact);
  const currentContactItem = () => {
    if (currentContact && currentContact['reset_email'] != null) {
      if (regexEmail.test(currentContact['reset_email'])) {
        return (
          <>
            {t(
              translations.verificationRestorePasswordBySmsPage
                .confirmMessageForEmail,
            )}
            <span>{currentContact['reset_email']}</span>
          </>
        );
      } else {
        return (
          <>
            {t(
              translations.verificationRestorePasswordBySmsPage
                .confirmMessageForPhone,
            )}
            <span>{currentContact['reset_email']}</span>
          </>
        );
      }
    }

    if (currentContact && currentContact['phone'] != null) {
      return (
        <>
          {t(
            translations.verificationRestorePasswordBySmsPage
              .confirmMessageForPhone,
          )}
          <span>{currentContact['phone']}</span>
        </>
      );
    }
  };

  const history = useHistory();

  return (
    <>
      <Title>
        <TriggerBackPreviousPage
          onClick={() => {
            if (previousStep) {
              previousStep(false);
            }
          }}
        />
        {t(translations.verificationRestorePasswordBySmsPage.title)}
      </Title>

      <ConfirmMessage>{currentContactItem()}</ConfirmMessage>

      <VarificationCodeInputWrapper>
        <StyledInput
          fields={4}
          fieldHeight={60}
          fieldWidth={50}
          className={apiErrors.length > 0 ? 'hasError' : ''}
          onComplete={value => {
            $apiClient
              .post(validateSmsCodeEndpoint, {
                sms_code: value,
                new_user: newUser,
                environment_type: 'promo',
              })
              .then(function (response) {
                setApiErrors('');

                if (response && response['error']) {
                  setApiErrors(response['message']);
                  return;
                } else {
                  if (closePopupProp) {
                    closePopupProp(true);
                  }

                  if (response['redirect']) {
                    window.location.href = response['redirect'];
                  }
                }
              })
              .catch(function (error) {
                console.log(error);
              });
          }}
        />
        {apiErrors ? <ErrorText>Невірний код</ErrorText> : null}
      </VarificationCodeInputWrapper>

      <TriggerResendCodeWrapper>
        <TriggerResendCodeItem
          onClick={() => {
            if (newUser && currentContact) {
              resendSmsCodeToPhone({
                email: currentContact['phone'],
                newUser: true,
              });
            } else {
              if (
                currentContact &&
                regexEmail.test(currentContact['reset_email'])
              ) {
                resendSmsCode(currentContact);
              } else {
                resendSmsCodeToPhone(currentContact);
              }
            }
          }}
        >
          {currentContact && regexEmail.test(currentContact['reset_email']) ? (
            isLoadingEmail ? (
              <LoadingIndicator small />
            ) : (
              t(translations.verificationRestorePasswordBySmsPage.resendCode)
            )
          ) : isLoadingPhone ? (
            <LoadingIndicator small />
          ) : (
            t(translations.verificationRestorePasswordBySmsPage.resendCode)
          )}
        </TriggerResendCodeItem>
      </TriggerResendCodeWrapper>

      {currentContact && currentContact['reset_email'] != null && (
        <TriggerResendCodeWrapper>
          <TriggerResendCodeItem
            onClick={() => {
              if (
                currentContact != null &&
                regexEmail.test(currentContact['reset_email'])
              ) {
                resendSmsCodeToPhone({
                  reset_email: 'email_' + currentContact['reset_email'],
                });
              } else {
                resendSmsCode({
                  reset_email: 'email_' + currentContact['reset_email'],
                });
              }
            }}
          >
            {regexEmail.test(currentContact['reset_email']) ? (
              isLoadingPhone ? (
                <LoadingIndicator small />
              ) : (
                t(
                  translations.verificationRestorePasswordBySmsPage
                    .sendCodeToPhone,
                )
              )
            ) : isLoadingEmail ? (
              <LoadingIndicator small />
            ) : (
              t(
                translations.verificationRestorePasswordBySmsPage
                  .sendCodeToEmail,
              )
            )}
          </TriggerResendCodeItem>
        </TriggerResendCodeWrapper>
      )}
    </>
  );
};

export default VerificationRestorePasswordBySmsForm;
