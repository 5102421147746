import i18next from 'i18next';
import { translations } from 'locales/i18n';

import phoneIcon from '../../../../assets/icons/phone-cyan.svg';

let fieldsList = {};

function localeFillData() {
  fieldsList = {
    phone: {
      name: 'phone',
      type: 'text',
      icon: phoneIcon,
      placeholder: i18next.t(
        translations.resetPasswordByPhonePage.form.phone.placeholder,
      ),
    },
  };
}

// init locale filling data
localeFillData();

// language changed event
i18next.on('languageChanged init', () => {
  localeFillData();
});

export default fieldsList;
