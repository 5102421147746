import React, { useState, useEffect } from 'react';

import { Formik, Form, FormikErrors, FormikValues } from 'formik';

// form data
import fieldsList from './formData';

// locales
import { useTranslation } from 'react-i18next';
import { translations } from 'locales/i18n';

// validation schema
import { loginSchema } from './loginSchema';

// types
import { InputProps } from '../../../atoms/Input/types';
import { ILoginFormProps } from './types';
import {
  ButtonGroups,
  ButtonSizes,
  ButtonTypes,
} from '../../../atoms/Button/types';

// components
import Button from '../../../atoms/Button';

// styles
import {
  InputBox,
  StyledInput,
  ButtonContainer,
  TriggerLinkResetPwd,
  Forgot,
  TriggerLink,
  Text,
} from '../styles';
import { useLocation, useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectError } from '../../../store/login/selectors';

// result login form
export const LoginForm = ({ submit, loading }: ILoginFormProps) => {
  // locales hook
  const { t } = useTranslation();

  const [formErrors, setFormErrors] = useState<FormikErrors<FormikValues>>({});
  const [disable, setDisable] = useState<boolean>(false);

  // location hook
  const history = useHistory();
  // const [userEmail, setUserEmail] = UseLocalStorage('email', '');
  // const [userPassword, setUserPassword] = UseLocalStorage('password', '');
  const isAuthError = useSelector(selectError);

  const formElementsArray: InputProps[] = [];
  let timeout: number = 0;

  Object.keys(fieldsList).forEach(key => {
    formElementsArray.push(fieldsList[key]);
  });

  useEffect(() => {
    setDisable(!!Object.keys(formErrors).length || !!isAuthError || false);
  }, [formErrors, isAuthError]);

  return (
    <>
      <Formik
        initialValues={{
          login_email: '',
          login_password: '',
          remember: true,
        }}
        validationSchema={loginSchema}
        onSubmit={(values, actions) => {
          submit(values);
        }}
      >
        {({ values, errors }) => {
          setFormErrors(errors);
          return (
            <Form>
              <InputBox>
                {formElementsArray.map((item, index) => (
                  <StyledInput {...item} key={index} />
                ))}
              </InputBox>
              <Forgot>
                <TriggerLinkResetPwd
                  onClick={e => {
                    clearTimeout(timeout);
                    timeout = window.setTimeout(function () {
                      history.push('/reset-password-by-email');
                    }, 100);
                  }}
                >
                  {t(translations.loginPage.actions.resetPasswordLabel)}
                </TriggerLinkResetPwd>
              </Forgot>

              <ButtonContainer>
                <Button
                  id="login"
                  text={t(translations.loginPage.actions.login)}
                  type={ButtonTypes.Submit}
                  group={ButtonGroups.Primary}
                  size={ButtonSizes.Large}
                  disabled={disable}
                  loading={loading}
                />
              </ButtonContainer>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

export default LoginForm;
