import styled from 'styled-components/macro';

import mq from '../../utils/mediaQueriesBp';

export const H2 = styled.h2`
  font-size: 22px;
  line-height: 26px;
  font-weight: 700;
  color: ${p => p.theme.colors.black};
  margin: 0;

  ${mq('tablet')} {
    font-size: 36px;
    line-height: 42px;
  }
`;

export default H2;
