import React, { useEffect, useState } from 'react';
import { Formik, Form, useFormik } from 'formik';

import { actions as signUpByPhoneActions } from '../../../../store/signUpByPhone/slice';
import { actions as resetByEmailActions } from '../../../../store/resetPasswordByEmail/slice';
import { selectError } from '../../../../store/resetPasswordByEmail/selectors';

import { useDispatch, useSelector } from 'react-redux';

// form data
import fieldsList from './formData';

// locales
import { useTranslation } from 'react-i18next';
import { translations } from 'locales/i18n';

// validation schema
import { resetPasswordByEmailSchema } from './resetPasswordByEmailSchema';

// types
import { InputProps } from '../../../../atoms/Input/types';
import { IResetPasswordByEmailFormProps } from './types';
import {
  ButtonGroups,
  ButtonSizes,
  ButtonTypes,
} from '../../../../atoms/Button/types';

// components
import Button from '../../../../atoms/Button';

// styles
import {
  InputBox,
  StyledInput,
  ButtonContainer,
  TriggerBackPreviousPage,
  Title,
} from '../styles';
import { useHistory } from 'react-router-dom';
import { ErrorText } from '../../../../atoms/ErrorText';

// result reset password form
export const ResetPasswordForm = ({
  submit,
  loading,
}: IResetPasswordByEmailFormProps) => {
  // locales hook
  const { t } = useTranslation();

  const history = useHistory();

  // dispatch
  const dispatch = useDispatch();

  //  selector
  const isHasError = useSelector(selectError);

  const formElementsArray: InputProps[] = [];
  const [formError, setFormError] = useState(false);

  useEffect(() => {
    if (isHasError) {
      setFormError(true);
    }
  }, [isHasError]);

  Object.keys(fieldsList).forEach(key => {
    formElementsArray.push(fieldsList[key]);
  });

  return (
    <>
      <Title>
        <TriggerBackPreviousPage
          onClick={() => {
            dispatch(signUpByPhoneActions.fetchFailed(''));
            dispatch(resetByEmailActions.fetchFailed(''));
            history.goBack();
          }}
        />
        {t(translations.resetPasswordByEmailPage.title)}
      </Title>
      <Formik
        initialValues={{ reset_email: '' }}
        validationSchema={resetPasswordByEmailSchema}
        onSubmit={(values, actions) => {
          dispatch(resetByEmailActions.fetchFailed(''));
          submit(values);
        }}
      >
        {({ values, errors }) => (
          <Form>
            <InputBox>
              {formElementsArray.map(item => (
                <StyledInput key={item.name} {...item} />
              ))}
            </InputBox>

            {formError &&
              Object.keys(errors).length === 0 &&
              isHasError === 'phone_not_found' && (
                <ErrorText>
                  Перевірте, чи вірно ви вказали телефон. Потрібно вказати у
                  форматі +380 та без пробілів. Цей номер не прив’язаний до
                  жодного кабінету. Якщо ви не зареєстровані – зареєструйтесь.
                </ErrorText>
              )}

            {formError &&
              Object.keys(errors).length === 0 &&
              isHasError === 'email_not_found' && (
                <ErrorText>
                  Перевірте, чи вірно ви вказали email. Цей email не прив’язаний
                  до жодного кабінету. Якщо ви не зареєстровані –
                  зареєструйтесь.
                </ErrorText>
              )}

            <ButtonContainer>
              <Button
                text={t(translations.resetPasswordByEmailPage.actions.button)}
                type={ButtonTypes.Submit}
                group={ButtonGroups.Primary}
                size={ButtonSizes.Large}
                loading={loading}
              />
            </ButtonContainer>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default ResetPasswordForm;
