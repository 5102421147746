import React, { Component, useEffect, useState, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import TagManager from 'react-gtm-module';
import { useDispatch, useSelector } from 'react-redux';
import {
  useInjectReducer,
  useInjectSaga,
} from '../../../utils/redux-injectors';

import axios from 'axios';

import { ReactComponent as Logo } from '../../../assets/images/promo/logo-cardio-promo-new.svg';
import { ReactComponent as Cardio } from '../../../assets/images/NewCardio.svg';

// store
import {
  sliceKey as appSliceKey,
  reducer as appReducer,
  actions as appActions,
} from '../../../store/app/slice';

import {
  actions as favoritesActions,
  reducer as favoritesReducer,
  sliceKey as favoritesSliceKey,
} from '../../../store/favorites/slice';

import {
  sliceKey as profileSliceKey,
  reducer as profileReducer,
  actions as profileActions,
} from '../../../store/profile/slice';

import { favoritesSaga } from '../../../store/favorites/saga';
import {
  selectFavoritesData,
  selectFavoritesDone,
} from '../../../store/favorites/selectors';

import {
  selectIsAuthenticated,
  selectIsCookiesAccepted,
} from '../../../store/app/selectors';

// hooks
import { useWindowSize } from '../../../hooks/useWindowSize';

// components
import StyledBurger from '../../../atoms/Burger';

// styles
import {
  Wrapper,
  TopBarContainer,
  TopBarInner,
  TopBar,
  PreloaderWrap,
  LogoWrapper,
  Cookies,
  CookiesText,
  CookiesButton,
  ButtonIcon,
  NavMenu,
  MenuItem,
  UserActionButton,
  Link,
  Profile,
  ProfileWrapper,
  ButtonIconBookmark,
  BookmarkLink,
} from './styles';

import { menu } from '../../../config/menu';
import { ReactComponent as UserIcon } from '../../../assets/images/promo/user.svg';
import { ReactComponent as MenuBookmark } from '../../../assets/images/promo/menu-bookmark.svg';
import { translations } from '../../../locales/i18n';
import {
  selectProfile,
  selectProfileData,
} from '../../../store/profile/selectors';
import { useTranslation } from 'react-i18next';
import { profileSaga } from '../../../store/profile/saga';

// api breakpoint
const baseUrl = process.env.REACT_APP_API_BASE_URL + 'api/v1/translations';

export function Header() {
  // inject reducer && saga
  useInjectSaga({ key: profileSliceKey, saga: profileSaga });
  useInjectReducer({ key: appSliceKey, reducer: appReducer });
  useInjectReducer({ key: profileSliceKey, reducer: profileReducer });

  useInjectSaga({ key: favoritesSliceKey, saga: favoritesSaga });
  useInjectReducer({ key: favoritesSliceKey, reducer: favoritesReducer });

  // local state
  const [burgerOpened, setBurgerOpened] = useState(false);
  const [isSticky, setSticky] = useState(false);
  const [isMobile, setMobile] = useState(false);
  const [isMainPage, setMainPage] = useState(false);
  const [isActive, setActive] = useState(false);
  const [isFavoritesTotal, setFavoritesTotal] = useState(false);

  // locales hook
  const { t } = useTranslation();

  // location hook
  const location = useLocation();

  // window size watcher
  const { width } = useWindowSize();

  // dispatch
  const dispatch = useDispatch();
  const setCookiesAccepted = () => dispatch(appActions.setCookiesAccepted());
  const getProfile = () => dispatch(profileActions.fetchTrigger());
  const getFavorites = () => dispatch(favoritesActions.fetchTrigger());

  // selectors
  const isAccepted = useSelector(selectIsCookiesAccepted);
  const isLoggedIn = useSelector(selectIsAuthenticated);
  const profileInfo = useSelector(selectProfile);
  const profileData = useSelector(selectProfileData);
  const favoritesData = useSelector(selectFavoritesData);
  const wrapperRef = useRef<HTMLDivElement>(null);

  // fetch data from api
  const [translation, setTranslation] = React.useState({
    header_phone: '',
    header_text: '',
  });

  const [show, setShow] = useState('flex');

  const fetchTranslation = () => {
    axios
      .get(`${baseUrl}`)
      .then(response => {
        const translated = response.data;

        setTranslation(translated);
      })
      .catch(error => console.error(`Error: ${error}`));
  };

  useEffect(() => {
    fetchTranslation();
    setTimeout(function () {
      setShow('none');
    }, 1500);
  }, []);

  const handleCookiesBtn = () => setCookiesAccepted();

  useEffect(() => {
    if (wrapperRef.current !== null && location.pathname === '/') {
      setMainPage(true);

      if (window.pageYOffset === 0) {
        setSticky(false);
      }

      const scrollHandler = () => {
        setSticky(window.pageYOffset > 0 && width > 1024);
      };

      window.addEventListener('scroll', scrollHandler);

      return () => {
        window.removeEventListener('scroll', scrollHandler);
      };
    } else {
      if (window.pageYOffset === 0) {
        setSticky(false);
        setMainPage(false);
      }
      const scrollHandler = () => {
        setSticky(window.pageYOffset > 89 && width > 1024);
      };
      window.addEventListener('scroll', scrollHandler);

      return () => {
        window.removeEventListener('scroll', scrollHandler);
      };
    }
  });

  useEffect(() => {
    if (width < 1025) {
      setMobile(true);
    } else {
      setMobile(false);
      setBurgerOpened(false);
    }
  });

  useEffect(() => {
    setBurgerOpened(false);
  }, [location]);

  useEffect(() => {
    if (!profileData && isLoggedIn) {
      getProfile();
    }
  }, [profileData]);

  useEffect(() => {
    if (isLoggedIn) {
      getFavorites();
    }
  }, []);

  useEffect(() => {
    if (isLoggedIn) {
      favoritesData && favoritesData['meta']['total'] > 0
        ? setFavoritesTotal(true)
        : setFavoritesTotal(false);
    }
  }, [favoritesData]);

  return (
    <Wrapper>
      <Cookies style={{ display: isAccepted === 'true' ? 'none' : 'flex' }}>
        <CookiesText>Цей веб-сайт використовує файли cookie.</CookiesText>
        <CookiesButton onClick={handleCookiesBtn}>Так</CookiesButton>
      </Cookies>
      <PreloaderWrap style={{ display: show }}>
        <Cardio />
      </PreloaderWrap>
      <TopBarContainer
        isSticky={isSticky}
        isMainPage={isMainPage}
        ref={wrapperRef}
      >
        <TopBarInner>
          <TopBar>
            <LogoWrapper to={'/'}>
              <Logo />
            </LogoWrapper>
            <NavMenu open={burgerOpened} mobile={isMobile}>
              {menu.map(item =>
                item.name === 'cardioprostir' ? (
                  <Link
                    key={item.name}
                    href={item.slug}
                    target="_blank"
                    onClick={() => {
                      if (isLoggedIn) {
                        const tagManagerArgs = {
                          gtmId: process.env.REACT_APP_GTM_ID,
                          dataLayer: {
                            event: 'menu_header_click', //Статическое значение.
                            category: 'Menu Header Click', //Статическое значение
                            action: t(translations.nav[item.name]), //Название раздела меню (верхний уровень) к которому принадлежит пункт меню.
                            label: t(translations.nav[item.name]), //Название пункта меню, по которому кликнул пользователь.
                            uid: profileInfo?.data?.id, //User ID. Id зарегистрированного пользователя.
                          },
                        };

                        TagManager.initialize(tagManagerArgs);
                      }
                    }}
                  >
                    <MenuItem>{t(translations.nav[item.name])}</MenuItem>
                  </Link>
                ) : (
                  <Link key={item.name} href={item.slug}>
                    <MenuItem>{t(translations.nav[item.name])}</MenuItem>
                  </Link>
                ),
              )}
            </NavMenu>
            <ProfileWrapper open={burgerOpened}>
              {isLoggedIn && profileData ? (
                <>
                  {favoritesData && (
                    <BookmarkLink
                      to={'/saved'}
                      state={{ isFavoritesTotal: isFavoritesTotal }}
                    >
                      <ButtonIconBookmark>
                        {favoritesData['meta']['total'] > 0 ? (
                          <span>{favoritesData['meta']['total']}</span>
                        ) : (
                          <span>0</span>
                        )}
                        <MenuBookmark />
                      </ButtonIconBookmark>
                    </BookmarkLink>
                  )}
                  <Profile to={'/profile'}>
                    <UserActionButton isSticky={false}>
                      <ButtonIcon isSticky={false}>
                        <UserIcon />
                      </ButtonIcon>

                      {width > 1024 &&
                        profileData.firstName + ' ' + profileData.lastName}
                    </UserActionButton>
                  </Profile>
                </>
              ) : (
                <Profile
                  to={{
                    pathname: `/login`,
                    state: { background: location },
                  }}
                >
                  <UserActionButton isSticky={false}>
                    <ButtonIcon isSticky={false}>
                      <UserIcon />
                    </ButtonIcon>
                    {width > 1024 && t(translations.header.userActionButton)}
                  </UserActionButton>
                </Profile>
              )}
              {width < 1025 && (
                <StyledBurger
                  open={burgerOpened}
                  onClick={() => setBurgerOpened(!burgerOpened)}
                >
                  <div />
                  <div />
                  <div />
                </StyledBurger>
              )}
            </ProfileWrapper>
          </TopBar>
        </TopBarInner>
      </TopBarContainer>
    </Wrapper>
  );
}
