import { call, put, takeLatest } from 'redux-saga/effects';
import { actions as favoritesActions } from 'store/favorites/slice';
import {
  favoritesService,
  favoritesAddService,
  favoritesRemoveService,
} from './services';

import { toast } from 'react-toastify';

/**
 * Favorites request/response handler
 */
export function* fetchFavoritesSaga({
  payload,
}: ReturnType<typeof favoritesActions.fetchTrigger>) {
  try {
    const response = yield call(favoritesService);

    yield put(favoritesActions.fetchSuccess(response));
  } catch (err) {
    yield put(favoritesActions.fetchFailed(err));
  } finally {
    yield put(favoritesActions.fetchFulfilled());
  }
}

/**
 * Favorites add request/response handler
 */
export function* fetchFavoritesAddSaga({
  payload,
}: ReturnType<typeof favoritesActions.fetchAddTrigger>) {
  try {
    const response = yield call(favoritesAddService, payload);

    yield put(favoritesActions.fetchAddSuccess(response));

    toast.success('Додано до збережених');
    yield put(favoritesActions.fetchTrigger());
  } catch (err) {
    yield put(favoritesActions.fetchAddFailed(err));
  } finally {
    yield put(favoritesActions.fetchAddFulfilled());
  }
}

/**
 * Favorites remove request/response handler
 */
export function* fetchFavoritesRemoveSaga({
  payload,
}: ReturnType<typeof favoritesActions.fetchRemoveTrigger>) {
  try {
    const response = yield call(favoritesRemoveService, payload);

    yield put(favoritesActions.fetchRemoveSuccess(response));

    toast.success('Видалено зі збережених');

    yield put(favoritesActions.fetchTrigger());
  } catch (err) {
    yield put(favoritesActions.fetchRemoveFailed(err));
  } finally {
    yield put(favoritesActions.fetchRemoveFulfilled());
  }
}

/**
 * Root saga manages watcher lifecycle
 */
export function* favoritesSaga() {
  yield takeLatest(favoritesActions.fetchTrigger.type, fetchFavoritesSaga);
  yield takeLatest(
    favoritesActions.fetchAddTrigger.type,
    fetchFavoritesAddSaga,
  );
  yield takeLatest(
    favoritesActions.fetchRemoveTrigger.type,
    fetchFavoritesRemoveSaga,
  );
}
