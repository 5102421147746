import React from 'react';
import { ReactComponent as Check } from '../../assets/icons/check-registered.svg';

// types
import { IProps } from './types';

// default props
import { defaultProps } from './defaultProps';

// atoms
import LoadingIndicator from '../LoadingIndicator';

// styles
import { StyledButton } from './styles';

export const Button: React.FC<IProps> = ({
  id,
  pageType,
  type,
  text,
  loading,
  group,
  size,
  click,
  disabled,
  children,
  checkIcon,
}) => {
  return (
    <StyledButton
      id={id}
      type={type}
      group={group}
      size={size}
      onClick={click}
      disabled={disabled}
      pageType={pageType}
      icon={checkIcon}
    >
      {checkIcon && <Check />}
      {children}
      {loading ? <LoadingIndicator small /> : text}
    </StyledButton>
  );
};

// set default props to component
Button.defaultProps = defaultProps;

export default Button;
