/*
 * Login Slice
 *
 */

import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { ContainerState, ActionTriggerProps } from './types';

// The initial state of the Login container
export const initialState: ContainerState = {
  loading: false,
  done: false,
  error: null,
};

const loginSlice = createSlice({
  name: 'login',
  initialState,
  reducers: {
    fetchTrigger(state, action: PayloadAction<ActionTriggerProps>) {
      state.loading = true;
      state.done = false;
      state.error = null;
    },
    fetchSuccess(state) {
      state.done = true;
    },
    fetchFailed(state, action: PayloadAction<string>) {
      state.error = action.payload;
    },
    fetchFulfilled(state) {
      state.loading = false;
      state.done = false;
    },
    updateState(state) {
      state.error = null;
    },
  },
});

export const { actions, reducer, name: sliceKey } = loginSlice;
