import React from 'react';
import { Formik, Form } from 'formik';

import { NavLink, useLocation } from 'react-router-dom';

// form data
import fieldsList from './formData';

// locales
import { useTranslation } from 'react-i18next';
import { translations } from 'locales/i18n';

// validation schema
import { resetPasswordCodeConfirmationSchema } from './resetPasswordCodeConfirmationSchema';

// types
import { InputProps } from '../../../../atoms/Input/types';
import { IResetPasswordCodeConfirmationFormProps } from './types';
import {
  ButtonGroups,
  ButtonSizes,
  ButtonTypes,
} from '../../../../atoms/Button/types';

// components
import Button from '../../../../atoms/Button';

// styles
import { InputBox, StyledInput, ButtonContainer, TriggerText } from '../styles';

// result login form
export const ResetPasswordCodeConfirmationForm = ({
  submit,
  setSuccess,
  loading,
}: IResetPasswordCodeConfirmationFormProps) => {
  // locales hook
  const { t } = useTranslation();

  // location hook
  const location = useLocation();

  const formElementsArray: InputProps[] = [];
  Object.keys(fieldsList).forEach(key => {
    formElementsArray.push(fieldsList[key]);
  });

  return (
    <Formik
      initialValues={{ code: '' }}
      validationSchema={resetPasswordCodeConfirmationSchema}
      onSubmit={(values, actions) => {
        // submit(values);
      }}
    >
      <Form>
        <InputBox>
          {formElementsArray.map(item => (
            <StyledInput key={item.name} {...item} />
          ))}
        </InputBox>

        <ButtonContainer>
          <Button
            text={t(translations.resetPasswordByPhonePage.actions.resetButton)}
            type={ButtonTypes.Submit}
            group={ButtonGroups.Primary}
            size={ButtonSizes.Regular}
            loading={false}
          />
        </ButtonContainer>

        <TriggerText onClick={() => console.log('new code')}>
          {t(translations.resetPasswordByPhonePage.actions.oneMoreCode)}
        </TriggerText>

        <NavLink
          to={{
            pathname: `/auth/restore-password`,
            state: { background: location },
          }}
        >
          go to restore
        </NavLink>
      </Form>
    </Formik>
  );
};

export default ResetPasswordCodeConfirmationForm;
