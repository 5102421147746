import styled from 'styled-components/macro';
import H2 from '../../../atoms/H2';
import ReactCodeInput from 'react-verification-code-input';
import { NavLink } from 'react-router-dom';

const Wrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  font-weight: 400;
  padding: 27px 0;
`;

const Title = styled(H2)`
  text-align: center;
  color: ${p => p.theme.colors.primary};
  font-size: 20px;
  line-height: 24px;
  margin-bottom: 15px;
  position: relative;
`;

const InputBox = styled.div`
  display: flex;
  justify-content: center;
`;

const VarificationCodeInputWrapper = styled.div`
  position: relative;
  padding-bottom: 40px;

  p {
    position: absolute;
    width: 100%;
    text-align: center;
    margin-top: 10px;
  }
`;

const StyledInput = styled(ReactCodeInput)`
  display: flex;
  justify-content: center;
  width: 100% !important;

  &.hasError {
    div {
      input {
        border: 1px solid ${p => p.theme.colors.red};

        &:focus {
          border: 1px solid ${p => p.theme.colors.red};
          & + input {
            border: 1px solid ${p => p.theme.colors.red};
          }
        }
      }
    }
  }

  div {
    input {
      margin: 0 5px;
      border: 1px solid ${p => p.theme.colors.border};
      border-radius: 4px !important;
      font-size: 24px;
      line-height: 28px;
      color: ${p => p.theme.colors.black};
      outline: none;
      background-color: transparent;
      text-align: center;
      font-family: 'Roboto', sans-serif;

      &:focus {
        border: 2px solid ${p => p.theme.colors.secondary};
        caret-color: ${p => p.theme.colors.black};

        & + input {
          border: 1px solid;
        }
      }
    }
  }
`;

const ConfirmMessage = styled.div`
  text-align: center;
  font-size: 14px;
  line-height: 16px;
  margin-bottom: 28px;
  margin-top: 16px;

  span {
    font-weight: 700;
  }
`;

const TriggerLink = styled(NavLink)`
  display: block;
  text-decoration: underline;
  color: ${p => p.theme.colors.secondary};
  text-align: center;
  margin-bottom: 14px;

  &:hover {
    text-decoration: none;
  }
`;

const TriggerResendCodeWrapper = styled.div`
  display: block;
  text-align: center;
  margin-bottom: 14px;
`;

const TriggerResendCodeItem = styled.span`
  text-decoration: underline;
  color: ${p => p.theme.colors.secondary};
  cursor: pointer;

  &:hover {
    text-decoration: none;
  }
`;

export {
  Wrapper,
  Title,
  InputBox,
  ConfirmMessage,
  StyledInput,
  TriggerLink,
  TriggerResendCodeWrapper,
  TriggerResendCodeItem,
  VarificationCodeInputWrapper,
};
