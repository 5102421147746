import styled from 'styled-components/macro';
import { NavLink } from 'react-router-dom';

export const ErrorText = styled.p`
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  margin: 5px 0 0 5px;
  color: ${p => p.theme.colors.red};
`;

export const ErrorTextLink = styled(NavLink)`
  color: ${p => p.theme.colors.red};
  text-decoration: underline;
  transition: all 0.5s;

  &:hover {
    text-decoration: none;
    transition: all 0.5s;
  }
`;

export default ErrorText;
