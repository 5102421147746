import React, { useState } from 'react';
import axios from 'axios';
import { useLocation } from 'react-router-dom';
import moment from 'moment';

import logo from '../../../assets/images/promo/logo_servier_new.svg';

import { ReactComponent as Instagram } from '../../../assets/icons/instagram.svg';
import { ReactComponent as Viber } from '../../../assets/icons/viber.svg';
import { ReactComponent as Telegram } from '../../../assets/icons/telegram.svg';
import { ReactComponent as Facebook } from '../../../assets/icons/facebook-logo.svg';
import { ReactComponent as FooterLeftIcon } from '../../../assets/images/promo/last-article-left-bottom.svg';
import { Disclaimer } from '../../features/Disclaimer';

// styles
import {
  Wrapper,
  FooterLeftIconWrapper,
  Link,
  Contact,
  Social,
  IconItem,
  FooterContainer,
  FooterMainWrapper,
  FooterCopyrightWrapper,
  FooterMain,
  FooterNav,
  FooterCopyright,
  FooterCopyrightText,
  LogoMain,
  LogoImg,
} from './styles';

// api breakpoint
const baseUrl = process.env.REACT_APP_API_BASE_URL + 'api/v1/translations';

export function Footer() {
  // locales hook
  // const { t } = useTranslation();

  // location hook
  const location = useLocation<{ singlePage?: boolean }>();
  // fetch data from api
  const [translation, setTranslation] = React.useState({
    footer_address_1: '',
  });
  const [footerLeftIconHidden, SetFooterLeftIconHidden] = useState(false);

  const fetchTranslation = () => {
    axios
      .get(`${baseUrl}`)
      .then(response => {
        const translated = response.data;

        setTranslation(translated);
      })
      .catch(error => console.error(`Error: ${error}`));
  };

  React.useEffect(() => {
    fetchTranslation();
  }, []);

  React.useEffect(() => {
    const excludePathList: string[] = ['/', 'registration', 'profile'];

    if (
      excludePathList.includes(location.pathname) ||
      (location.state && location.state.singlePage)
    ) {
      SetFooterLeftIconHidden(true);
    } else {
      SetFooterLeftIconHidden(false);
    }
  }, [location]);

  const { footer_address_1 } = translation;

  return (
    <>
      <Disclaimer />
      <Wrapper>
        <FooterLeftIconWrapper isLeftIconHidden={footerLeftIconHidden}>
          <FooterLeftIcon />
        </FooterLeftIconWrapper>
        <FooterMainWrapper>
          <FooterContainer>
            <FooterMain>
              <LogoMain>
                <Link
                  to={{
                    pathname: 'https://servier.ua/',
                  }}
                  target="_blank"
                >
                  <LogoImg src={logo} />
                </Link>
              </LogoMain>
              <FooterNav>
                <Link
                  to={process.env.PUBLIC_URL + '/rules.pdf'}
                  target="_blank"
                >
                  Угода користувача
                </Link>
                <Link
                  to={{
                    pathname:
                      'https://servier.ua/kontakty/#%d0%bf%d0%be%d0%b2%d1%96%d0%b4%d0%be%d0%bc%d0%bb%d0%b5%d0%bd%d0%bd%d1%8f-%d0%bf%d1%80%d0%be-%d0%bf%d0%be%d0%b1%d1%96%d1%87%d0%bd%d0%b5-%d1%8f%d0%b2%d0%b8%d1%89%d0%b5-%d0%bd%d0%b5%d0%b1%d0%b0%d0%b6',
                  }}
                  target="_blank"
                >
                  Повідомити про побічну реакцію
                </Link>
                <Link
                  to={{
                    pathname: 'https://servier.ua/ukr/contact',
                  }}
                  target="_blank"
                >
                  Форми для зв'язку
                </Link>
              </FooterNav>
              <Social>
                <Contact
                  to={{ pathname: `https://www.instagram.com/cardioprostir/` }}
                  target={'_blank'}
                >
                  <IconItem>
                    <Instagram width={26} height={26} />
                  </IconItem>
                </Contact>
                <Contact
                  to={{
                    pathname: 'https://t.me/joinchat/AAAAAE_nsfR1F7TeevcsUQ',
                  }}
                  target="_blank"
                >
                  <IconItem>
                    <Telegram width={26} height={26} />
                  </IconItem>
                </Contact>

                <Contact
                  to={{
                    pathname: 'https://cutt.ly/axtX81w',
                  }}
                  target="_blank"
                >
                  <IconItem>
                    <Viber width={26} height={26} />
                  </IconItem>
                </Contact>

                <Contact
                  to={{
                    pathname: 'https://www.facebook.com/cardioprostir',
                  }}
                  target="_blank"
                >
                  <IconItem>
                    <Facebook width={26} height={26} fill={'#ffffff'} />
                  </IconItem>
                </Contact>
              </Social>
            </FooterMain>
          </FooterContainer>
        </FooterMainWrapper>
        <FooterCopyrightWrapper>
          <FooterContainer>
            <FooterCopyright>
              <FooterCopyrightText>
                {footer_address_1} Copyright © {moment().year()}. Усі права
                захищені
              </FooterCopyrightText>
            </FooterCopyright>
          </FooterContainer>
        </FooterCopyrightWrapper>
      </Wrapper>
    </>
  );
}
