import i18next from 'i18next';
import { translations } from 'locales/i18n';

import * as Yup from 'yup';

let loginSchema = Yup.object();
const phoneRegex = /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/;

function localeFillData() {
  loginSchema = Yup.object().shape({
    login_email: Yup.string()
      .required(i18next.t(translations.loginPage.form.email.rules.required))
      .matches(
        /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})|^\+?3?8?(0\d{9})$/,
        i18next.t(translations.loginPage.form.email.rules.invalid),
      ),
    login_password: Yup.string()
      .required(i18next.t(translations.loginPage.form.password.rules.required))
      .min(6, i18next.t(translations.loginPage.form.password.rules.minSize))
      .max(100, i18next.t(translations.loginPage.form.password.rules.maxSize)),
  });
}

// init locale filling data
localeFillData();

// language changed event
i18next.on('languageChanged init', () => {
  localeFillData();
});

export { loginSchema };
