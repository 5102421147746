import * as React from 'react';

import Breadcrumbs from '../../components/layout/Breadcrumbs';

import { Wrapper } from './styles';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

export function AppLayout({ children }) {
  const [isMainPageTemplate, setMainPageTemplate] = useState(false);

  // location hook
  const location = useLocation();

  useEffect(() => {
    if (location.pathname === '/') {
      setMainPageTemplate(true);
    } else {
      setMainPageTemplate(false);
    }
  });

  return <Wrapper isMainPageTemplate={isMainPageTemplate}>{children}</Wrapper>;
}
