import * as React from 'react';
import { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';

import { Helmet } from 'react-helmet-async';
import { useHistory, useLocation } from 'react-router-dom';

// locales
import { useTranslation } from 'react-i18next';
import { translations } from 'locales/i18n';

// store
import { loginSaga } from '../../store/login/saga';
import { signUpByPhoneSaga } from '../../store/signUpByPhone/saga';
import { appSaga } from '../../store/app/saga';
import { profileSaga } from '../../store/profile/saga';

import {
  actions,
  reducer as loginReducer,
  sliceKey as loginSliceKey,
} from '../../store/login/slice';

import {
  reducer as appReducer,
  sliceKey as appSliceKey,
} from '../../store/app/slice';

import { sliceKey as profileSliceKey } from '../../store/profile/slice';

import {
  actions as signUpByPhoneActions,
  reducer as signUpByPhoneReducer,
  sliceKey as signUpByPhoneSliceKey,
} from '../../store/signUpByPhone/slice';

import { selectLoading } from '../../store/login/selectors';
import { selectDone } from '../../store/signUpByPhone/selectors';

import {
  selectIsAuthenticated,
  selectLocation,
} from '../../store/app/selectors';

// components
import LoginForm from './LoginForm';
import RegistrationForm from './RegistrationForm';

// styles
import { IframeWrapper, Wrapper } from './styles';
import Tabs from '../../atoms/TabsLoginPopup';
import VerificationRestorePasswordBySmsForm from '../VerificationBySmsCode/VerificationRestorePasswordBySmsPage/VerificationRestorePasswordBySmsForm';
import crossDomainAuth from '../../utils/crossDomainAuth';

interface IProps {
  activeTab?: number;
  closePopupMainComponent?: (data: boolean) => void;
}

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

export function LoginPage({ activeTab, closePopupMainComponent }: IProps) {
  // inject reducer && saga
  useInjectSaga({ key: loginSliceKey, saga: loginSaga });
  useInjectSaga({ key: signUpByPhoneSliceKey, saga: signUpByPhoneSaga });
  useInjectSaga({ key: appSliceKey, saga: appSaga });

  useInjectReducer({ key: loginSliceKey, reducer: loginReducer });
  useInjectReducer({
    key: signUpByPhoneSliceKey,
    reducer: signUpByPhoneReducer,
  });
  useInjectReducer({ key: appSliceKey, reducer: appReducer });

  useInjectSaga({ key: profileSliceKey, saga: profileSaga });

  const [success, setSuccess] = useState(false);
  const [currentContact, setCurrentContact] = useState();
  const [closePopup, setClosePopup] = useState(false);

  const [urlRedirect, setUrlRedirect] = useState('');

  // locales hook
  const { t } = useTranslation();

  // location hook
  const location = useLocation();

  // dispatch
  const dispatch = useDispatch();
  const onLogin = payload => {
    dispatch(actions.fetchTrigger(payload));
  };

  const onRegistration = payload => {
    setCurrentContact(payload);
    dispatch(signUpByPhoneActions.fetchTrigger(payload));
  };

  // selectors
  const isLoggedIn = useSelector(selectIsAuthenticated);
  const currentLocation = useSelector(selectLocation) || '/';

  const isLoading = useSelector(selectLoading);
  const isLoadingReg = useSelector(selectLoading);
  const isDone = useSelector(selectDone);

  const backPreviousStep = formStepValue => {
    setSuccess(formStepValue);
  };

  const closePopupVerified = value => {
    setClosePopup(value);
    if (closePopupMainComponent) {
      closePopupMainComponent(value);
    }
  };

  // get query params
  const query = useQuery();

  // check redirect
  const redirectTo = query.get('redirect_to');

  useEffect(() => {
    if (isDone) {
      setSuccess(true);
    }
  }, [isDone]);

  useEffect(() => {
    if (activeTab === 0) {
      crossDomainAuth(setUrlRedirect, currentLocation);
    } else if (activeTab === 1) {
      crossDomainAuth(setUrlRedirect, currentLocation, true);
    }
  }, [
    localStorage.getItem('access_token'),
    localStorage.getItem('remember_token'),
    isLoggedIn,
  ]);

  const items = [
    {
      title: t(translations.loginPage.title_tab_1),
      content: <LoginForm loading={isLoading} submit={onLogin} />,
      url: 'login?redirect_to=profile',
    },
    {
      title: t(translations.loginPage.title_tab_2),
      content: (
        <RegistrationForm loading={isLoadingReg} submit={onRegistration} />
      ),
      url: '/registration',
    },
  ];

  return (
    <>
      <Helmet>
        <title>{t(translations.loginPage.seo.title)}</title>
        <meta
          name="description"
          content={t(translations.loginPage.seo.description)}
        />
      </Helmet>
      <Wrapper active={success}>
        {location.pathname == '/login' ? (
          <IframeWrapper>
            <iframe src={urlRedirect} id="frame_auth" />
          </IframeWrapper>
        ) : (
          ''
        )}
        {!success ? (
          <Tabs items={items} activeTab={activeTab} />
        ) : (
          <VerificationRestorePasswordBySmsForm
            currentContact={currentContact}
            previousStep={backPreviousStep}
            closePopupProp={closePopupVerified}
            newUser={true}
          />
        )}
      </Wrapper>
    </>
  );
}
