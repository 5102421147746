import React, { useState } from 'react';
import { TabsWrapper, Tab, TabContentWrapper } from './styles';
import { useHistory, useLocation } from 'react-router-dom';

import { actions as signUpByPhoneActions } from '../../store/signUpByPhone/slice';
import { actions as loginActions } from '../../store/login/slice';

import { useDispatch } from 'react-redux';

const TabContent = ({ content }) => (
  <TabContentWrapper>
    <div>{content}</div>
  </TabContentWrapper>
);

export default function Tabs({ items, activeTab }) {
  const [isActive, setActive] = useState<number>(activeTab);

  // location hook
  let history = useHistory();

  // dispatch
  const dispatch = useDispatch();

  const openTab = e => {
    dispatch(signUpByPhoneActions.fetchFailed(''));
    dispatch(loginActions.fetchFailed(''));
    history.push(e.target.dataset.url);
  };

  return (
    <>
      <TabsWrapper>
        {items.map((n, i) => (
          <Tab
            active={isActive === i}
            onClick={openTab}
            data-index={i}
            data-url={n.url}
            key={i}
          >
            {n.title}
          </Tab>
        ))}
      </TabsWrapper>
      {items[activeTab] && <TabContent {...items[activeTab]} />}
    </>
  );
}
