import i18next from 'i18next';
import { translations } from 'locales/i18n';

import * as Yup from 'yup';

let resetPasswordByPhoneSchema = Yup.object();

function localeFillData() {
  resetPasswordByPhoneSchema = Yup.object().shape({
    phone: Yup.string()
      .required(
        i18next.t(
          translations.resetPasswordByPhonePage.form.phone.rules.required,
        ),
      )
      .max(
        15,
        i18next.t(
          translations.resetPasswordByPhonePage.form.phone.rules.maxSize,
        ),
      ),
  });
}

// init locale filling data
localeFillData();

// language changed event
i18next.on('languageChanged init', () => {
  localeFillData();
});

export { resetPasswordByPhoneSchema };
