import { linkToMainSite } from '../utils/urlToCardioprostir';

export const menu = [
  {
    name: 'webinars',
    slug: '/webinars',
  },
  {
    name: 'special-projects',
    slug: '/specialprojects',
  },
  {
    name: 'clinical-cases',
    slug: '/clinical-cases',
  },
  {
    name: 'products',
    slug: `/products`,
  },
  {
    name: 'cardioprostir',
    slug: `${process.env.REACT_APP_BASE_MAIN_URL}`,
  },
];
