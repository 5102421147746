import { call, put, takeLatest } from 'redux-saga/effects';
import { actions } from './slice';
import resetPasswordByEmailService from './services';

/**
 * Reset Password by email request/response handler
 */
export function* fetchResetPasswordByEmailSaga({
  payload,
}: ReturnType<typeof actions.fetchTrigger>) {
  try {
    yield call(resetPasswordByEmailService, payload);
    yield put(actions.fetchSuccess());
  } catch (err) {
    yield put(actions.fetchFailed(err.response.data.message));
  } finally {
    yield put(actions.fetchFulfilled());
  }
}

/**
 * Root saga manages watcher lifecycle
 */
export function* resetPasswordByEmailSaga() {
  yield takeLatest(actions.fetchTrigger.type, fetchResetPasswordByEmailSaga);
}
