import styled from 'styled-components/macro';

// atoms
import H2 from '../../atoms/H2';
import Input from '../../atoms/Input';
import { NavLink } from 'react-router-dom';

interface IActiveProps {
  active: boolean;
}

const Wrapper = styled.div<IActiveProps>`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  font-weight: 400;
  ${props =>
    props.active &&
    `
    padding: 27px 25px 50px;
  `}
`;

const IframeWrapper = styled.div`
  display: none;
`;

const Title = styled(H2)`
  text-align: center;
  color: ${p => p.theme.colors.primary};
`;

const InputBox = styled.div`
  margin: 45px 0 0;
  // padding: 10px 20px;
  // border: 1px solid ${p => p.theme.colors.lightBlue};
`;

const StyledInput = styled(Input)`
  margin-bottom: 10px;
`;

const ButtonContainer = styled.div`
  text-align: center;
  width: 100%;
  margin-top: 30px;

  button {
    max-width: 200px;
    border-radius: 4px;
  }
`;

const Forgot = styled.div`
  text-align: left;
`;

const TriggerText = styled.div`
  display: inline;
  cursor: pointer;
  color: ${p => p.theme.colors.secondary};
`;

const TriggerLink = styled(NavLink)`
  display: inline;
  text-decoration: underline;
  color: ${p => p.theme.colors.secondary};

  &:hover {
    text-decoration: none;
  }
`;

const TriggerLinkResetPwd = styled.div`
  display: inline;
  text-decoration: underline;
  color: ${p => p.theme.colors.secondary};
  cursor: pointer;

  &:hover {
    text-decoration: none;
  }
`;

const Text = styled.div`
  text-align: center;
  white-space: pre-wrap;
  margin-top: 22px;

  a {
    color: ${p => p.theme.colors.secondary};

    &:hover {
      text-decoration: none;
    }
  }
`;

export {
  Wrapper,
  IframeWrapper,
  Title,
  InputBox,
  StyledInput,
  ButtonContainer,
  Forgot,
  TriggerText,
  TriggerLink,
  TriggerLinkResetPwd,
  Text,
};
