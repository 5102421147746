import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
  html {
    height: 100%;
    width: 100%;
    line-height: 1.4;
    -webkit-tap-highlight-color: transparent;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  body {
	  height: 100%;
    font-family: 'Roboto', sans-serif;
    font-weight: 300;
    font-size: 16px;
    line-height: 20px;
    color: #29293A;
    background-color: white;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
  }
  
  #root {
    display: flex;
    flex-direction: column;
    position: relative;
    min-width: 310px;
    min-height: 100%;
    z-index: 0;
  }
  
  h1, h2, p {
    margin: 0;
  }
  
  input {
    border-top-style: hidden;
    border-right-style: hidden;
    border-left-style: hidden;
  }
  
  p {
    font-weight: 300;
    font-size: 16px;
    line-height: 20px;
  }
  
  ul {
    width: 100%;
    list-style: none;
    padding: 0;
    
    li {
      position: relative;
      font-size: 16px;
      line-height: 20px;
      padding-left: 30px;
      margin-bottom: 10px;
      
      &:before {
        content: "";
        position: absolute;
        top: 5px;
        left: 0;
        height: 10px;
        width: 10px;
        border-radius: 50%;
        background: ${props => props.theme.colors.primary};
      }
      
      &:last-child {
        margin: 0;
      }
    }
  }
  
  ol {
    width: 100%;
    padding: 0 20px;
    
    li {
      position: relative;
      font-size: 16px;
      line-height: 20px;
      padding-left: 10px;
      margin-bottom: 10px;
    }
  }
  
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }
  
  button {
    font-family: inherit;
  }
`;
