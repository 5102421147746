import { Wrapper } from './styles';

export function Disclaimer() {
  return (
    <Wrapper>
      Будь ласка, візьміть до уваги, що відео (матеріали), яке знаходяться в
      цьому розділі, містять промоційну інформацію щодо рецептурних препаратів
      компанії Серв’є. Лікарські засоби є зареєстрованими, опис відповідає
      зареєстрованій інструкції з медичного застосування. Інформація призначена
      виключно для професіоналів сфери охорони здоров’я.
      <br />
      <br />
      Ми цінуємо Вашу професійну незалежність, тому перегляд відео (матеріалів)
      не зобов’язує Вас рекомендувати, призначати, промотувати та/або іншим
      способом використовувати продукти Серв’є, а також не спонукає до прийняття
      будь-яких рішень, дій чи бездіяльності на користь Серв’є та/або до
      спонукання інших осіб до таких або подібних дій.
    </Wrapper>
  );
}
