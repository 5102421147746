import { call, put, takeLatest } from 'redux-saga/effects';
import { actions } from './slice';
import resetPasswordByPhoneService from './services';

/**
 * Reset Password by phone request/response handler
 */
export function* fetchResetPasswordByPhoneSaga({
  payload,
}: ReturnType<typeof actions.fetchTrigger>) {
  try {
    yield call(resetPasswordByPhoneService, payload);
    yield put(actions.fetchSuccess());
  } catch (err) {
    yield put(actions.fetchFailed(err));
  } finally {
    yield put(actions.fetchFulfilled());
  }
}

/**
 * Root saga manages watcher lifecycle
 */
export function* resetPasswordByPhoneSaga() {
  yield takeLatest(actions.fetchTrigger.type, fetchResetPasswordByPhoneSaga);
}
