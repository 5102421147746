import styled from 'styled-components/macro';
import Autocomplete from 'react-google-autocomplete';

interface IStyledInputProps {
  active?: boolean;
  isPassword?: boolean;
  isError?: boolean;
  disabled?: boolean;
}

const Wrapper = styled.div`
  position: relative;

  label {
    position: absolute;
    left: 20px;
    top: 20px;
    pointer-events: none;
    color: #777777;

    &:after {
      content: '*';
      color: red;
    }
  }

  input {
    &:focus + label,
    &:disabled + label {
       {
        display: none;
      }
    }
  }
`;

const StyledTextInput = styled.input<IStyledInputProps>`
  height: 60px;
  width: 100%;
  padding: 20px;
  border: 1px solid ${p => p.theme.colors.border_2};
  border-radius: 4px;
  font-size: 16px;
  line-height: 20px;
  color: ${p => p.theme.colors.black};
  outline: none;
  background-color: transparent;

  &:focus {
    border: 2px solid ${p => p.theme.colors.secondary};
  }

  ${p =>
    p.isPassword &&
    `
    padding: 20px 45px 20px 20px;
  `}

  ${p =>
    p.isError &&
    `
    border: 2px solid ${p.theme.colors.red} !important;
  `}
`;

const SearchInput = styled(Autocomplete)<IStyledInputProps>`
  height: 60px;
  width: 100%;
  padding: 20px;
  border: 1px solid ${p => p.theme.colors.border_2};
  border-radius: 4px;
  font-size: 16px;
  line-height: 20px;
  color: ${p => p.theme.colors.black};
  outline: none;
  background-color: transparent;

  &:focus {
    border: 2px solid ${p => p.theme.colors.secondary};
  }

  ${p =>
    p.isError &&
    `
    border: 2px solid ${p.theme.colors.red} !important;
  `}
`;

const Label = styled.label`
  position: absolute;
  top: 6px;
  left: 0;
`;

const PasswordEye = styled.div<IStyledInputProps>`
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
  width: 20px;

  svg {
    position: absolute;
    &:last-of-type {
      display: none;
      left: -1px;
    }
  }

  ${p =>
    p.active &&
    `
    svg {
      &:first-of-type{
        display: none;
      }
      &:last-of-type {
        display: block;
      }
    }
  `}

  :hover {
    svg {
      path {
        fill: ${p => p.theme.colors.secondary};
      }
    }
  }
`;

const InputErrorIcon = styled.div<IStyledInputProps>`
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
  width: 20px;
`;

export {
  Wrapper,
  Label,
  StyledTextInput,
  PasswordEye,
  InputErrorIcon,
  SearchInput,
};
