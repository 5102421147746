import React, { FC, useState, useRef, useEffect } from 'react';
import { useField } from 'formik';
import { $apiClient } from 'utils/request';
import { ReactComponent as EyeIcon } from '../../assets/icons/eye.svg';
import { ReactComponent as EyeIconActive } from '../../assets/icons/eye-active.svg';
import { ReactComponent as InputError } from '../../assets/icons/input-error.svg';

// types
import { InputProps } from './types';

// atoms
import ErrorText from '../ErrorText';

// styles
import {
  Wrapper,
  StyledTextInput,
  Label,
  PasswordEye,
  InputErrorIcon,
  SearchInput,
} from './styles';
import { actions } from '../../store/login/slice';
import { actions as resetByEmailActions } from '../../store/resetPasswordByEmail/slice';
import { useDispatch, useSelector } from 'react-redux';
import { selectError } from '../../store/login/selectors';

export const Input: FC<InputProps> = props => {
  const [passwordIsVisible, setPasswordIsVisible] = useState(false);
  const [number, setNumber] = useState('');
  const [apiErrors, setApiErrors] = useState('');
  const [defaultCity, setDefaultCity] = useState('');
  const [field, meta, helpers] = useField(props);

  const change = event => {
    let val = event.target.value;
    val = val.replace(/ /gm, '');

    let num = `${val.substring(0, 4)} ${val.substring(4, 6)} ${val.substring(
      6,
      9,
    )} ${val.substring(9, 11)} ${val.substring(11, val.length)}`;

    num = num.trim();

    if (+val) {
      setNumber(num);
    }

    return val == num.replace(/ /gm, '');
  };
  const focus = () => {
    number === '' ? setNumber('+380') : setNumber(number);
  };
  const dispatch = useDispatch();
  const onChangeLoginValues = () => dispatch(actions.updateState());
  const isAuthError = useSelector(selectError);

  useEffect(() => {
    if (isAuthError) {
      setApiErrors(
        isAuthError.response.data.message
          ? isAuthError.response.data.message
          : 'default',
      );
    }
  }, [isAuthError]);

  useEffect(() => {
    if (field.name === 'city') {
      setDefaultCity(field.value);
    }
  });

  const urlEmailValidateEndPoint = 'api/v1/validate-user-email';

  let searchCityInput = (
    <SearchInput
      id={props.name}
      placeholder={props.customPlaceholder ? '' : props.placeholder}
      apiKey={process.env.REACT_APP_GOOGLE}
      translate={'yes'}
      language={'uk'}
      options={{
        types: ['(cities)'],
        language: 'uk',
        componentRestrictions: { country: 'ua' },
      }}
      defaultValue={defaultCity}
      onPlaceSelected={place => {
        if (props.cityValueCallBack && props.name === 'city') {
          props.cityValueCallBack(place.formatted_address);
        }
      }}
      onChange={e => {
        const targetCity = e.target as HTMLInputElement;

        field.onChange(e);

        if (props.cityValueCallBack && props.name === 'city') {
          props.cityValueCallBack(targetCity.value);
        }
      }}
      isError={!!(meta.touched && meta.error)}
      disabled={props.disabled}
    />
  );

  let resInput = (
    <StyledTextInput
      {...field}
      name={props.name}
      type={passwordIsVisible ? 'text' : props.type}
      placeholder={props.customPlaceholder ? '' : props.placeholder}
      isPassword={props.type === 'password'}
      onBlur={e => {
        if (props.name === 'login_email' && e.target.value !== '') {
          $apiClient
            .post(urlEmailValidateEndPoint, { email: e.target.value })
            .then(function (response) {
              setApiErrors('');
              if (response && response['error'])
                setApiErrors(response['message']);
            })
            .catch(function (error) {
              console.log(error);
            });
        }
      }}
      onChange={e => {
        if (props.name === 'reset_email' && e.target.value.length === 0) {
          dispatch(resetByEmailActions.fetchFailed(''));
        }
        if (props.name === 'login_email' || props.name === 'login_password') {
          onChangeLoginValues();
        }
        if (props.name === 'login_email') {
          setApiErrors('');
        }

        field.onChange(e);
      }}
      disabled={props.disabled}
      isError={
        !!(meta.touched && meta.error) ||
        (props.name === 'login_email' && !!apiErrors)
          ? !apiErrors
          : false
      }
    />
  );

  let resInputPhone = (
    <StyledTextInput
      {...field}
      name={props.name}
      type={props.type}
      placeholder={props.placeholder}
      disabled={props.disabled}
      value={number}
      onChange={e => {
        console.log('heeeeee 1');
        let num = change(e);
        if (num) {
          console.log('heeeeee 2');
          field.onChange(e);
        }
      }}
      onFocus={focus}
      maxLength={17}
      isError={!!(meta.touched && meta.error)}
    />
  );

  return (
    <Wrapper className={props.className}>
      {props.isNumber
        ? resInputPhone
        : props.name === 'city'
        ? searchCityInput
        : resInput}

      {props.customPlaceholder && resInput.props.value === '' && (
        <label>{props.placeholder}</label>
      )}

      {props.type === 'password' && (
        <PasswordEye
          active={passwordIsVisible}
          onClick={() => setPasswordIsVisible(!passwordIsVisible)}
        >
          <EyeIcon />
          <EyeIconActive />
        </PasswordEye>
      )}

      {props.type !== 'password' && meta.touched && meta.error && (
        <InputErrorIcon>
          <InputError />
        </InputErrorIcon>
      )}

      {meta.touched && meta.error ? (
        <ErrorText>{meta.error}</ErrorText>
      ) : props.name === 'login_email' && !!apiErrors ? (
        apiErrors === 'user_not_found_email' ? (
          <>
            <InputErrorIcon>
              <InputError />
            </InputErrorIcon>
            <ErrorText>
              Перевірте, чи вірно ви вказали email. Ця електронна адреса не
              прив’язана до жодного кабінету. Якщо ви не зареєстровані –
              зареєструйтесь.{' '}
            </ErrorText>
          </>
        ) : apiErrors === 'user_not_found_phone' ? (
          <>
            <InputErrorIcon>
              <InputError />
            </InputErrorIcon>
            <ErrorText>
              Перевірте, чи вірно ви вказали телефон. Потрібно вказати у форматі
              +380 та без пробілів. Цей номер не прив’язаний до жодного
              кабінету. Якщо ви не зареєстровані – зареєструйтесь.{' '}
            </ErrorText>
          </>
        ) : (
          <ErrorText>Перевірте, чи вірно ви вказали пароль. </ErrorText>
        )
      ) : null}
    </Wrapper>
  );
};

export default Input;
