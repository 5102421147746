import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'types';
import { initialState } from './slice';

// First select the relevant part from the state
const selectDomain = (state: RootState) => state.signUpByPhone || initialState;

export const selectData = createSelector(
  [selectDomain],
  signUpByPhoneState => signUpByPhoneState.data,
);

export const selectLoading = createSelector(
  [selectDomain],
  signUpByPhoneState => signUpByPhoneState.loading,
);

export const selectDone = createSelector(
  [selectDomain],
  signUpByPhoneState => signUpByPhoneState.done,
);

export const selectError = createSelector(
  [selectDomain],
  signUpByPhoneState => signUpByPhoneState.error,
);
