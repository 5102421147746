import styled from 'styled-components/macro';

import mq from '../../../utils/mediaQueriesBp';

export const ResponsiveContainer = styled.div`
  display: flex;
  width: 100%;
  padding: 0 12px;
  margin: 0 auto;
  max-width: 1440px;

  ${mq('tablet')} {
    padding: 0 ${() => (20 / 480) * 100}vw;
  }

  ${mq('laptop')} {
    padding: 0 ${() => (50 / 768) * 100}vw;
  }

  ${mq('desktop')} {
    padding: 0 60px;
  }
`;

export default ResponsiveContainer;
