import i18next from 'i18next';
import { translations } from 'locales/i18n';

import * as Yup from 'yup';

let resetPasswordCodeConfirmationSchema = Yup.object();

function localeFillData() {
  resetPasswordCodeConfirmationSchema = Yup.object().shape({
    code: Yup.string()
      .required(
        i18next.t(
          translations.resetPasswordByPhonePage.form.code.rules.required,
        ),
      )
      .max(
        15,
        i18next.t(
          translations.resetPasswordByPhonePage.form.code.rules.maxSize,
        ),
      ),
  });
}

// init locale filling data
localeFillData();

// language changed event
i18next.on('languageChanged init', () => {
  localeFillData();
});

export { resetPasswordCodeConfirmationSchema };
