import { toast } from 'react-toastify';

import { call, put, takeLatest } from 'redux-saga/effects';
import { actions as appActions } from 'store/app/slice';
import { actions as profileActions } from 'store/profile/slice';
import { actions as loginActions } from './slice';
import { actions as favoriteActions } from '../favorites/slice';
import loginService from './services';

/**
 * Login request/response handler
 */
export function* fetchLoginSaga({
  payload,
}: ReturnType<typeof loginActions.fetchTrigger>) {
  try {
    const { accessToken, rememberToken } = yield call(loginService, payload);

    yield localStorage.setItem('remember_token', rememberToken);

    yield put(loginActions.fetchSuccess());
    yield put(appActions.loginSuccess(accessToken));
    yield put(profileActions.fetchTrigger());
    yield put(favoriteActions.fetchTrigger());
  } catch (err) {
    yield put(loginActions.fetchFailed(err));
    if (err.response.status === 401)
      toast.error(
        'Ваш аккаунт не підтверджений. Для підтвердження перейдіть на пошту',
      );
  } finally {
    yield put(loginActions.fetchFulfilled());
  }
}

/**
 * Root saga manages watcher lifecycle
 */
export function* loginSaga() {
  yield takeLatest(loginActions.fetchTrigger.type, fetchLoginSaga);
}
