import styled from 'styled-components/macro';

import { NavLink } from 'react-router-dom';

import mq from '../../../utils/mediaQueriesBp';

import topMenuRedirectIcon from '../../../assets/images/promo/top-menu-redirect-icon.svg';
import userProfileLabelBg from '../../../assets/images/promo/user-profile-label-bg.svg';

import ResponsiveContainer from '../ResponsiveContainer';

interface NavProps {
  isSticky?: boolean;
  isMainPage?: boolean;
}

interface FavoritesProps {
  state?: any;
  isFavoritesTotal?: boolean;
}

interface IProps {
  open: boolean;
  mobile?: boolean;
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const TopBarContainer = styled.div<NavProps>`
  display: flex;
  align-items: center;
  position: fixed;
  overflow: hidden;
  top: 0;
  left: 0;
  width: 100%;
  width: 100%;
  background: white;
  box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.05);
  z-index: 9;
  height: 60px;
  transition: all 0.3s ease-in-out;

  ${mq('desktop')} {
    height: 90px;
    background: #eaf6fb;
  }

  ${p =>
    p.isMainPage &&
    `
    ${mq('desktop')} {
      height: 140px;
      box-shadow: none;
      background: transparent;
      
      ${
        p.isSticky &&
        `
        height: 90px;
        background: #EAF6FB;
      `
      }
    }
  `}
`;

const TopBarInner = styled(ResponsiveContainer)`
  // max-width: 1322px;
  // width: 100%;
  // margin: 0 auto;
  // padding: 0 0 0 20px;
`;

const TopBar = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  padding-top: 10px;
  padding-bottom: 10px;
`;

const LogoWrapper = styled(NavLink)`
  text-decoration: none;
  max-width: 122px;
  width: 100%;
  transition: all 0.5s ease;

  ${mq('desktop')} {
    max-width: 154px;
    margin-right: 30px;
  }

  ${mq('middle')} {
    max-width: 204px;
    margin-right: 40px;
  }

  ${mq('large')} {
    max-width: 254px;
    margin-right: 50px;
  }

  &:hover {
    transform: scale(1.05);
  }
`;

const TopInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  max-width: 400px;

  ${mq('desktop')} {
    max-width: unset;
  }
`;

const Phone = styled.a`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  color: white;
  cursor: pointer;
  font-weight: 500;
  font-size: 26px;
  line-height: 30px;
  text-transform: uppercase;
  text-decoration: none;
  transition: all 0.5s ease;

  &:hover {
    opacity: 0.8;
    transform: scale(1.05);
  }

  ${mq('tablet')} {
    font-size: 20px;
    line-height: 24px;
  }

  ${mq('laptop')} {
    font-size: 26px;
    line-height: 30px;
  }

  & > :first-child {
    margin-right: 10px;
  }
`;

const Text = styled.p`
  font-weight: 300;
  font-size: 20px;
  line-height: 19px;
  color: white;
  text-align: right;
`;
const TextLogo = styled.p`
  font-weight: bold;
  font-style: italic;
  color: #ffffff;
  text-transform: uppercase;
  margin-left: 10px;
  line-height: 13px;
  font-size: 16px;
  margin-bottom: -2px;
`;
// const CookiesWrapper = styled.div`
//   position: fixed;
//   top: 0;
//   bottom: 0;
//   left: 0;
//   right: 0;
//   width: 100%;
//   height: 100vh;
//   // backdrop-filter: blur(5px);
//   z-index: 1005;
// `;
const Cookies = styled.div`
  width: 100%;
  height: 80px;
  // background: #0071ba;
  background: ${props => props.theme.colors.nav_purple};
  position: fixed;
  bottom: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 15%;
  z-index: 1006;
`;
const CookiesText = styled.div`
  color: white;
  font-weight: 500;
  font-size: 18px;
`;

const CookiesButton = styled.div`
  width: 120px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #95c11f;
  color: white;
  cursor: pointer;
`;
const PreloaderWrap = styled.div`
  position: fixed;
  width: 100vw;
  height: 100vh;
  background: #ffffff;
  z-index: 1005;
  display: flex;
  align-items: center;
  justify-content: center;
  svg path {
    stroke-dasharray: 281;
    -webkit-animation: dash 5s infinite linear forwards;
  }
  @-webkit-keyframes dash {
    from {
      stroke-dashoffset: 814;
    }

    to {
      stroke-dashoffset: -814;
    }
  }

  @keyframes dash {
    from {
      stroke-dashoffset: 814;
    }

    to {
      stroke-dashoffset: -814;
    }
  }
`;

const NavMenu = styled.div<IProps>`
  align-items: center;
  justify-content: space-between;

  ${p =>
    p.mobile &&
    `
  position: fixed;
  top: 60px;
  left: 100%;
  bottom: 0;
  margin: 0;
  width: 100%;
  height: calc(100% - 60px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #EAF6FB;
  transition: all .5s;
  `};

  ${p =>
    p.mobile &&
    `
    @media (orientation: landscape) {
      padding: 50px 0;
      justify-content: flex-start;
      overflow: auto;
    }
  `};

  ${p =>
    p.open &&
    `
      position: fixed;
      left: 0;
      z-index: -1;
    `};

  ${mq('desktop')} {
    position: relative;
    display: flex;
    flex-grow: 1;
    justify-content: flex-start;
  }
`;

const MenuItem = styled.div``;

const ButtonIcon = styled.div<NavProps>`
  display: flex;
  align-items: center;
  justify-content: center;

  ${mq('desktop')} {
    margin-right: 14px;
  }
`;

const StyledLink = styled.a`
  position: relative;
  text-decoration: none;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  color: ${p => p.theme.colors.black};
  margin-bottom: 60px;
  transition: all 0.5s;

  // ${mq('laptop')} {
  //   font-size: 18px;
  //   line-height: 20px;
  // }

  ${mq('desktop')} {
    margin-right: 15px;
    margin-bottom: 0;
    font-size: 13px;
    line-height: 17px;
  }

  ${mq('middle')} {
    margin-right: 30px;
    margin-bottom: 0;
    font-size: 14px;
    line-height: 17px;
  }

  ${mq('large')} {
    font-size: 16px;
    line-height: 19px;
    margin-right: 40px;
  }

  &.active {
    color: ${p => p.theme.colors.primary};
    font-weight: 700;

    &:after {
      content: '';
      position: absolute;
      width: 120%;
      height: 3px;
      left: -10%;
      bottom: -12px;
      background-color: ${p => p.theme.colors.cyan};
    }
  }

  &:last-of-type {
    margin-bottom: 0;
    margin-right: 0;
    font-weight: 700;

    ${mq('desktop')} {
      margin-left: 15px;
    }

    ${mq('middle')} {
      margin-left: 30px;
    }

    ${mq('large')} {
      margin-left: 20px;
    }

    div {
      display: flex;
      align-items: center;

      &:after {
        content: '';
        background-image: url(${topMenuRedirectIcon});
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        width: 15px;
        height: 10px;
        display: inline-block;
        margin-left: 10px;
      }
    }
  }

  &:hover {
    color: ${p => p.theme.colors.primary};
  }
`;

const UserActionButton = styled.div<NavProps>`
  display: flex;
  align-items: center;
  text-decoration: none;
  transition: all 0.5s;

  &:hover {
    color: ${props => props.theme.colors.cyan};
  }
`;

const ProfileWrapper = styled.div<IProps>`
  position: relative;
  min-width: 114px;
  height: 42px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-left: 10px;

  ${mq('desktop')} {
    min-width: 205px;
    max-width: 205px;
    height: 66px;
    padding-left: 15px;
    justify-content: flex-start;
  }

  ${mq('middle')} {
    min-width: 254px;
    max-width: 254px;
    height: 66px;
    padding-left: 35px;
    justify-content: flex-start;
  }

  ${mq('large')} {
    min-width: 294px;
    max-width: 294px;
  }

  &:before {
    content: '';
    display: inline-flex;
    position: absolute;
    left: -10px;
    width: 100vw;
    height: 100%;
    transform: skewX(-15deg);
    background: ${p =>
      p.open ? p.theme.colors.backgroundLightBlue : p.theme.colors.white};
    border-radius: 15px;
    transition: all 0.5s;
  }
`;

const Profile = styled(NavLink)`
  text-decoration: none;
  font-weight: 400;
  font-size: 13px;
  line-height: 15px;
  margin-right: 20px;
  color: ${p => p.theme.colors.black};
  z-index: 9;
`;

const ButtonIconBookmark = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 26px;
  height: 26px;
  position: relative;

  span {
    font-size: 10px;
    width: 14px;
    height: 14px;
    color: ${props => props.theme.colors.primary};
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${props => props.theme.colors.lightBlue};
    position: absolute;
    right: -2px;
    top: -2px;
    border-radius: 50px;
    font-weight: 500;
    transition: all 0.5s;
  }

  svg {
    path {
      transition: all 0.5s;
    }
  }
`;

const BookmarkLink = styled(NavLink)<FavoritesProps>`
  display: inline-flex;
  margin-right: 25px;

  ${props =>
    props.state.isFavoritesTotal &&
    `
    ${ButtonIconBookmark} {
      span {
        color: ${props.theme.colors.white};
        background-color: ${props.theme.colors.pink};
      }
    }
  `}

  &:hover {
    ${ButtonIconBookmark} {
      span {
        background-color: #a3d4e4;
        ${props =>
          props.state.isFavoritesTotal &&
          `
          background-color: #BB2543;
        `}
      }
      svg {
        path {
          fill: ${props => props.theme.colors.cyan};
        }
      }
    }
  }

  ${mq('desktop')} {
    margin-right: 35px;
  }
`;

export {
  Wrapper,
  TopBarContainer,
  TopBarInner,
  TopBar,
  TopInfo,
  Phone,
  Text,
  TextLogo,
  LogoWrapper,
  PreloaderWrap,
  Cookies,
  CookiesButton,
  CookiesText,
  NavMenu,
  MenuItem,
  ButtonIcon,
  UserActionButton,
  StyledLink as Link,
  Profile,
  ProfileWrapper,
  ButtonIconBookmark,
  BookmarkLink,
};
