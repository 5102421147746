import i18next from 'i18next';
import { translations } from 'locales/i18n';

import mailIcon from '../../../assets/icons/email-profile.svg';
import lockIcon from '../../../assets/icons/lock.svg';

let fieldsList = {};

function localeFillData() {
  fieldsList = {
    email: {
      name: 'login_email',
      type: 'text',
      // isNumber: true,
      icon: mailIcon,
      placeholder: i18next.t(translations.loginPage.form.email.placeholder),
    },
    password: {
      name: 'login_password',
      type: 'password',
      icon: lockIcon,
      placeholder: i18next.t(translations.loginPage.form.password.placeholder),
    },
  };
}

// init locale filling data
localeFillData();

// language changed event
i18next.on('languageChanged init', () => {
  localeFillData();
});

export default fieldsList;
