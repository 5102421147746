import styled from 'styled-components/macro';
import mq from '../../utils/mediaQueriesBp';

interface IProps {
  isMainPageTemplate: boolean;
}

const Wrapper = styled.div<IProps>`
  display: flex;
  flex: 1;
  flex-direction: column;

  ${props =>
    !props.isMainPageTemplate &&
    `
    padding-top: 60px;
  
    ${mq('desktop')} {
      padding-top: 90px;
    }
  `}
`;

export { Wrapper };
