import styled from 'styled-components/macro';

import mq from '../../../utils/mediaQueriesBp';

import { NavLink } from 'react-router-dom';
import ResponsiveContainer from '../ResponsiveContainer';
import FooterLeftIcon from '../../../assets/images/promo/last-article-left-bottom.svg';

interface IProps {
  isLeftIconHidden: boolean;
}

const Wrapper = styled.footer`
  width: 100%;
  background-color: ${p => p.theme.colors.nav_purple};
  padding: 35px 0 15px;
  position: relative;

  ${mq('desktop')} {
    padding: 55px 0 15px;
  }
`;

const FooterLeftIconWrapper = styled.div<IProps>`
  position: absolute;
  bottom: 84%;
  z-index: 0;
  left: -40px;

  ${props =>
    props.isLeftIconHidden &&
    `
    display: none;
  `}

  ${mq('laptop')} {
    left: -50px;
  }

  svg {
    width: 115px;
    ${mq('laptop')} {
      width: auto;
    }
  }
`;

const ContactsWrapper = styled.div`
  width: 100%;
  background-color: ${p => p.theme.colors.backgroundLightBlue};
`;
const Link = styled(NavLink)``;

const Contact = styled(NavLink)``;

const IconItem = styled.div`
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.3s ease-in-out;

  svg {
    fill: #ffffff;
  }

  &:hover {
    opacity: 0.6;
  }
`;

const Social = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  a {
    margin-right: 30px;

    &:last-of-type {
      margin-right: 0;
    }
  }
`;

const FooterContainer = styled(ResponsiveContainer)``;
const FooterMainWrapper = styled.div``;
const FooterCopyrightWrapper = styled.div``;

const FooterMain = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding-bottom: 35px;

  ${mq('desktop')} {
    flex-direction: row;
    padding-bottom: 55px;
  }
`;

const FooterNav = styled.div`
  display: flex;
  flex-direction: column;

  margin-bottom: 40px;

  ${mq('laptop')} {
    flex-direction: row;
  }

  ${mq('desktop')} {
    margin-bottom: 0;
  }

  a {
    text-decoration: none;
    font-size: 13px;
    line-height: 15px;
    color: ${props => props.theme.colors.white};
    margin-bottom: 15px;
    transition: all 0.5s;
    text-align: center;

    &:last-of-type {
      margin-right: 0;
      margin-bottom: 0;
    }

    &:hover {
      color: ${props => props.theme.colors.cyan};
    }

    ${mq('laptop')} {
      margin-right: 40px;
      margin-bottom: 0;
    }

    ${mq('large')} {
      margin-right: 90px;
    }
  }
`;

const FooterCopyright = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;
const FooterCopyrightText = styled.p`
  text-align: center;
  font-size: 10px;
  line-height: 12px;
  color: ${props => props.theme.colors.lightPurple};
`;

const LogoMain = styled.div`
  margin-bottom: 40px;

  ${mq('desktop')} {
    margin-bottom: 0;
  }
`;

const LogoImg = styled.img`
  object-fit: contain;
  max-width: 200px;
  width: 100%;
`;

export {
  Wrapper,
  FooterLeftIconWrapper,
  ContactsWrapper,
  Social,
  Contact,
  IconItem,
  FooterContainer,
  FooterMainWrapper,
  FooterCopyrightWrapper,
  FooterMain,
  FooterCopyright,
  FooterCopyrightText,
  FooterNav,
  LogoMain,
  LogoImg,
  Link,
};
