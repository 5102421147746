import styled from 'styled-components/macro';
import mq from '../../utils/mediaQueriesBp';

interface ActiveButton {
  active: boolean;
}

const TabsWrapper = styled.div`
  display: flex;
  width: 100%;
  border-bottom: 1px solid ${p => p.theme.colors.border};
`;
const Tab = styled.button<ActiveButton>`
  display: inline-flex;
  justify-content: center;
  position: relative;
  width: 50%;
  background-color: inherit;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 35px 10px 25px;
  color: ${props => props.theme.colors.cyan};
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  font-weight: 400;

  ${mq('tablet')} {
    padding: 30px 10px;
  }

  ${props =>
    props.active &&
    `
      color: ${props.theme.colors.primary};
      font-weight: 600;
      
      &::before {
        content: '';
        position: absolute;
        bottom: -1px;
        width: 100%;
        height: 2px;
        background-color: ${props.theme.colors.primary};
        border-top-left-radius: 2px;
        border-top-right-radius: 2px;
      }
   `};
`;

const TabContentWrapper = styled.div`
  padding: 0 35px 35px;
`;

export { TabsWrapper, Tab, TabContentWrapper };
