import i18next from 'i18next';
import { translations } from 'locales/i18n';

import * as Yup from 'yup';

let resetPasswordByEmailSchema = Yup.object();

function localeFillData() {
  resetPasswordByEmailSchema = Yup.object().shape({
    reset_email: Yup.string().required(
      i18next.t(translations.loginPage.form.email.rules.required),
    ),
    // .matches(
    //   /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})|([0-9]{10})+$/,
    //   i18next.t(translations.loginPage.form.email.rules.invalid),
    // ),
  });
}

// init locale filling data
localeFillData();

// language changed event
i18next.on('languageChanged init', () => {
  localeFillData();
});

export { resetPasswordByEmailSchema };
