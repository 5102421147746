import styled from 'styled-components/macro';

import { NavLink } from 'react-router-dom';

// atoms
import H2 from '../../../atoms/H2';
import Input from '../../../atoms/Input';
import { ReactComponent as LongArrow } from '../../../assets/icons/long-arrow-left.svg';

const Wrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  padding: 27px 25px 50px;
`;

const Title = styled(H2)`
  text-align: center;
  color: ${p => p.theme.colors.primary};
  font-size: 20px;
  line-height: 24px;
  position: relative;
  padding: 0 25px;
`;

const InputBox = styled.div`
  margin: 45px 0 0;
`;

const StyledInput = styled(Input)`
  margin-bottom: 10px;
`;

const ButtonContainer = styled.div`
  text-align: center;
  width: 100%;
  margin-top: 20px;

  button {
    max-width: 200px;
    border-radius: 4px;
    font-size: 16px;
  }
`;

const TriggerLink = styled(NavLink)`
  display: inline;
  text-decoration: none;
  color: ${p => p.theme.colors.secondary};
`;

const Text = styled.div`
  font-weight: 400;
  text-align: center;
  white-space: pre-wrap;
  margin-top: 20px;
`;

const SuccessContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 35px;
`;

const TriggerBackPreviousPage = styled(LongArrow)`
  cursor: pointer;
  position: absolute;
  left: 0;
  top: calc(50% - 16px / 2);
`;

export {
  Wrapper,
  Title,
  InputBox,
  StyledInput,
  ButtonContainer,
  TriggerLink,
  Text,
  SuccessContainer,
  TriggerBackPreviousPage,
};
