import { useState } from 'react';
import { useDispatch } from 'react-redux';
import snakeCaseKeys from 'snakecase-keys';
import camelCaseKeys from 'camelcase-keys';

import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';

// actions
import { actions as logoutActions } from 'store/app/slice';

import { $apiClient } from '../utils/request';

let isRefreshing = false;
let refreshQueue: {
  resolve: (access_token: string) => void;
  reject: (err: any) => void;
}[] = [];

const useHttpClient = (
  httpClientInstance: AxiosInstance,
): [string | null, () => void] => {
  // local error state
  const [error, setError] = useState<string | null>(null);
  // actions
  const dispatch = useDispatch();
  const onLogout = () => dispatch(logoutActions.logoutTrigger());

  // request interceptor
  httpClientInstance.interceptors.request.use(
    async (config: AxiosRequestConfig) => {
      setError(null);

      // formatting data
      if (config.data) {
        config.data =
          config.data instanceof FormData
            ? config.data
            : snakeCaseKeys(config.data, { deep: true });
      }

      // formatting headers
      const accessToken = await localStorage.getItem('access_token');
      if (accessToken) {
        config.headers.Authorization = `Bearer ${accessToken}`;
      }

      return config;
    },
    (err: any) => {
      setError(err);
      return Promise.reject(err);
    },
  );

  // response interceptor
  httpClientInstance.interceptors.response.use(
    (res: AxiosResponse) => {
      return camelCaseKeys(res.data, { deep: true });
    },
    error => {
      let formattedMessage: string | null = null;
      const originalRequest = error.config;

      switch (error.response.status) {
        case 401:
          if (
            localStorage.getItem('remember_token') &&
            localStorage.getItem('access_token')
          ) {
            refreshToken(onLogout);

            return new Promise((resolve, reject) => {
              refreshQueue.push({
                resolve: access_token => {
                  originalRequest.headers.Authorization =
                    'Bearer ' + access_token;
                  resolve(axios.request(originalRequest));
                },
                reject: err => {
                  reject(err);
                },
              });
            });
          }

          setError(formattedMessage);
          break;

        case 403:
          break;

        case 500:
          formattedMessage = 'Unknown error';
          break;

        default:
          break;
      }

      if (error.response.data.message) {
        formattedMessage = error.response.data.message;
      }

      if (error.response.data.errors) {
        Object.keys(error.response.data.errors).forEach(errorKey => {
          // @ts-ignore
          error.response.data.errors[errorKey].forEach((line: string) => {
            formattedMessage += `\r\n${line}`;
          });
        });
      }

      return Promise.reject(error);
    },
  );

  const errorClearedHandler = () => {
    setError(null);
  };

  return [error, errorClearedHandler];
};

function refreshToken(onLogout) {
  if (!isRefreshing) {
    isRefreshing = true;

    $apiClient
      .post('api/v1/auth/refresh-token', {
        remember_token: localStorage.getItem('remember_token'),
      })
      .then((res: any) => {
        $apiClient.defaults.headers.Authorization = res.accessToken;
        localStorage.setItem('access_token', res.accessToken);
        refreshQueue.forEach(v => {
          v.resolve(res.accessToken);
        });
        refreshQueue = [];
      })
      .catch(err => {
        refreshQueue.forEach(v => v.reject(err));
        refreshQueue = [];
        if (err.response.status == 404) {
          onLogout();
        }
      })
      .finally(() => {
        isRefreshing = false;
      });
  }
}

export default useHttpClient;
