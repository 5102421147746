export enum ButtonGroups {
  Primary = 'primary',
  Secondary = 'secondary',
  Tertiary = 'tertiary',
  OutlinedPrimary = 'outlinedTertiary',
  OutlinedPrimary_2 = 'outlinedPrimary',
  OutlinedSecondary = 'textSecondary',
  OutlinedTertiary = 'textTertiary',
}

export enum ButtonSizes {
  Regular = 'regular',
  Large = 'large',
  SuperLarge = 'superLarge',
}

export enum ButtonTypes {
  Submit = 'submit',
  Button = 'button',
}

export interface IProps {
  id?: string;
  pageType?: string;
  text?: string;
  type: ButtonTypes;
  loading?: boolean;
  group: ButtonGroups;
  size: ButtonSizes;
  click?: () => void;
  disabled?: boolean;
  children?: any;
  checkIcon?: boolean;
}
