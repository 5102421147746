import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';

import { useDispatch, useSelector } from 'react-redux';
import {
  useInjectReducer,
  useInjectSaga,
} from '../../../utils/redux-injectors';

// images
// import checkImage from '../../../assets/images/check.svg';

// locales
import { useTranslation } from 'react-i18next';
import { translations } from 'locales/i18n';

// store
import {
  sliceKey as resetPasswordSliceKey,
  reducer as resetPasswordReducer,
  actions as resetPasswordActions,
} from '../../../store/resetPasswordByEmail/slice';

// store
import {
  sliceKey as resetPasswordByPhoneSliceKey,
  reducer as resetPasswordByPhoneReducer,
  actions as resetPasswordByPhoneActions,
} from '../../../store/resetPasswordByPhone/slice';

import { resetPasswordByEmailSaga } from '../../../store/resetPasswordByEmail/saga';
import { resetPasswordByPhoneSaga } from '../../../store/resetPasswordByPhone/saga';

import {
  selectLoading,
  selectDone,
} from '../../../store/resetPasswordByEmail/selectors';

// components
import ResetPasswordByEmailForm from './ResetPasswordByEmailForm';
import VerificationRestorePasswordBySmsForm from '../../VerificationBySmsCode/VerificationRestorePasswordBySmsPage/VerificationRestorePasswordBySmsForm';

// styles
import {
  Wrapper,
  Title,
  Text,
  SuccessContainer,
  TriggerBackPreviousPage,
} from './styles';
import { useHistory, useLocation } from 'react-router-dom';

export function ResetPasswordByEmailPage() {
  // inject reducer && saga
  useInjectSaga({ key: resetPasswordSliceKey, saga: resetPasswordByEmailSaga });
  useInjectSaga({
    key: resetPasswordByPhoneSliceKey,
    saga: resetPasswordByPhoneSaga,
  });
  useInjectReducer({
    key: resetPasswordSliceKey,
    reducer: resetPasswordReducer,
  });
  useInjectReducer({
    key: resetPasswordByPhoneSliceKey,
    reducer: resetPasswordByPhoneReducer,
  });

  const [success, setSuccess] = useState(false);
  const [currentContact, setCurrentContact] = useState();

  const backPreviousStep = formStepValue => {
    setSuccess(formStepValue);
  };

  // locales hook
  const { t } = useTranslation();

  const history = useHistory();

  // dispatch
  const dispatch = useDispatch();
  const resetPassword = payload => {
    setCurrentContact(payload);
    dispatch(resetPasswordActions.fetchTrigger(payload));
  };

  // selectors
  const isLoading = useSelector(selectLoading);

  const isDone = useSelector(selectDone);

  useEffect(() => {
    if (isDone) {
      setSuccess(true);
    }
  }, [isDone]);

  return (
    <>
      <Helmet>
        <title>{t(translations.resetPasswordByEmailPage.seo.title)}</title>
        <meta
          name="description"
          content={t(translations.resetPasswordByEmailPage.seo.description)}
        />
      </Helmet>

      <Wrapper>
        {!success ? (
          <ResetPasswordByEmailForm
            loading={isLoading}
            submit={resetPassword}
          />
        ) : (
          <VerificationRestorePasswordBySmsForm
            currentContact={currentContact}
            previousStep={backPreviousStep}
          />
        )}
      </Wrapper>
    </>
  );
}
