import React from 'react';
import { Formik, Form } from 'formik';

// form data
import fieldsList from './formData';

// locales
import { useTranslation } from 'react-i18next';
import { translations } from 'locales/i18n';

// validation schema
import { resetPasswordByPhoneSchema } from './resetPasswordByPhoneSchema';

// types
import { InputProps } from '../../../../atoms/Input/types';
import { IResetPasswordByPhoneFormProps } from './types';
import {
  ButtonGroups,
  ButtonSizes,
  ButtonTypes,
} from '../../../../atoms/Button/types';

// components
import Button from '../../../../atoms/Button';

// styles
import { InputBox, StyledInput, ButtonContainer } from '../styles';

// result login form
export const ResetPasswordByPhoneForm = ({
  submit,
  setSuccess,
  loading,
}: IResetPasswordByPhoneFormProps) => {
  // locales hook
  const { t } = useTranslation();

  const formElementsArray: InputProps[] = [];
  Object.keys(fieldsList).forEach(key => {
    formElementsArray.push(fieldsList[key]);
  });

  return (
    <Formik
      initialValues={{ phone: '' }}
      validationSchema={resetPasswordByPhoneSchema}
      onSubmit={(values, actions) => {
        // submit(values);
      }}
    >
      <Form>
        <InputBox>
          {formElementsArray.map(item => (
            <StyledInput key={item.name} {...item} />
          ))}
        </InputBox>

        <ButtonContainer onClick={() => setSuccess()}>
          <Button
            text={t(translations.resetPasswordByPhonePage.actions.button)}
            type={ButtonTypes.Submit}
            group={ButtonGroups.Primary}
            size={ButtonSizes.Regular}
            loading={false}
          />
        </ButtonContainer>
      </Form>
    </Formik>
  );
};

export default ResetPasswordByPhoneForm;
