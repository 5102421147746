const REACT_APP_STAGE_MAIN_HOST = process.env.REACT_APP_STAGE_MAIN_HOST;
const REACT_APP_BASE_MAIN_URL = process.env.REACT_APP_BASE_MAIN_URL;
const REACT_APP_BASE_PROMO_URL = process.env.REACT_APP_BASE_PROMO_URL;

export function crossDomainAuth(
  setUrlRedirect: React.Dispatch<React.SetStateAction<string>>,
  currentLocation: string,
  isRegistration: boolean = false,
) {
  let url: any = '';
  const token = localStorage.getItem('access_token');
  const rememberToken = localStorage.getItem('remember_token');

  if (token && rememberToken) {
    if (isRegistration) localStorage.setItem('register_event', 'true');
    else localStorage.setItem('login_event', 'true');

    const currentSite = window.location.host;
    const authSite =
      currentSite === `${REACT_APP_STAGE_MAIN_HOST}`
        ? `${REACT_APP_BASE_PROMO_URL}`
        : `${REACT_APP_BASE_MAIN_URL}`;

    url +=
      authSite +
      'cross_auth?savedToken=' +
      token +
      '&savedRememberToken=' +
      rememberToken +
      '&redirectTo=' +
      getRedirectLink(currentLocation, isRegistration);

    window.location.href = url;
  }
}

function getRedirectLink(currentLocation: string, isRegistration: boolean) {
  if (isRegistration) {
    return process.env.REACT_APP_BASE_PROMO_URL;
  }

  if (window.sessionStorage.getItem('utmParams') !== null) {
    let utmParams = window.sessionStorage.getItem('utmParams') || '';
    let newURL = new URL(utmParams);

    window.sessionStorage.removeItem('utmParams');

    if (newURL) {
      return newURL.href;
    } else {
      return String(process.env.REACT_APP_BASE_PROMO_URL);
    }
  } else {
    if (currentLocation === '/specialprojects/prestilol/') {
      return (
        process.env.REACT_APP_BASE_PROMO_URL + 'specialprojects/prestilol/'
      );
    } else {
      const externalLocationUrl = /^https?:\/\//.test(currentLocation)
        ? new URL(currentLocation)
        : '';

      // check redirect
      if (externalLocationUrl !== '') {
        return currentLocation.replace(/^\//, '');
      }

      return (
        process.env.REACT_APP_BASE_PROMO_URL +
        currentLocation.replace(/^\//, '')
      );
    }
  }
}

export default crossDomainAuth;
