import styled from 'styled-components/macro';

const Wrapper = styled.div`
  position: relative;
  padding: 20px;
  font-size: 10px;
  width: 100%;
  color: ${p => p.theme.colors.black};
  text-align: center;
  line-height: 1;
  z-index: 1;
`;

export { Wrapper };
