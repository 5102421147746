import { call, put, takeLatest } from 'redux-saga/effects';
// import { actions as appActions } from 'store/app/slice';
import { actions as signUpByPhoneActions } from './slice';
import signUpByPhone from './services';

/**
 * Sign Up By Phone request/response handler
 */
export function* fetchSignUpByPhoneSaga({
  payload,
}: ReturnType<typeof signUpByPhoneActions.fetchTrigger>) {
  try {
    const response = yield call(signUpByPhone, payload);
    yield put(signUpByPhoneActions.fetchSuccess(response));
  } catch (err) {
    yield put(signUpByPhoneActions.fetchFailed(err));
  } finally {
    yield put(signUpByPhoneActions.fetchFulfilled());
  }
}

/**
 * Root saga manages watcher lifecycle
 */
export function* signUpByPhoneSaga() {
  yield takeLatest(
    signUpByPhoneActions.fetchTrigger.type,
    fetchSignUpByPhoneSaga,
  );
}
