import styled from 'styled-components/macro';

// atoms
import H2 from '../../../atoms/H2';
import Input from '../../../atoms/Input';

const Wrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
`;

const Title = styled(H2)`
  text-align: center;
  color: ${p => p.theme.colors.primary};
`;

const InputBox = styled.div`
  padding: 10px 20px;
  margin: 30px 0;
  border: 1px solid ${p => p.theme.colors.lightBlue};
`;

const StyledInput = styled(Input)`
  margin-bottom: 10px;
`;

const ButtonContainer = styled.div`
  text-align: center;
  width: 100%;

  button {
    max-width: 200px;
  }
`;

const TriggerText = styled.div`
  text-align: center;
  cursor: pointer;
  color: ${p => p.theme.colors.secondary};
  margin-top: 20px;
`;

const Text = styled.div`
  font-weight: 400;
  text-align: center;
  white-space: pre-wrap;
  margin-top: 20px;
`;

export {
  Wrapper,
  Title,
  InputBox,
  StyledInput,
  ButtonContainer,
  TriggerText,
  Text,
};
