import i18next from 'i18next';
import { translations } from 'locales/i18n';

import * as Yup from 'yup';

let registrationSchema = Yup.object();

function localeFillData() {
  registrationSchema = Yup.object().shape({
    phone: Yup.string()
      .required(
        i18next.t(translations.signUpPagePopUp.form.phone.rules.required),
      )
      .matches(
        /^(?:\+380)+?(\d{9}|\s(\d{2})+\s(\d{3})+(\s(\d{2})){2})/,
        i18next.t(translations.signUpPagePopUp.form.phone.rules.invalid),
      ),
    password: Yup.string()
      .required(
        i18next.t(translations.signUpPagePopUp.form.password.rules.required),
      )
      .min(
        6,
        i18next.t(translations.signUpPagePopUp.form.password.rules.minSize),
      )
      .max(
        100,
        i18next.t(translations.signUpPagePopUp.form.password.rules.maxSize),
      ),
  });
}

// init locale filling data
localeFillData();

// language changed event
i18next.on('languageChanged init', () => {
  localeFillData();
});

export { registrationSchema };
