import React, { useEffect, useState } from 'react';

import { Formik, Form, useField } from 'formik';

// form data
import fieldsList from './formData';

// locales
import { useTranslation, Trans } from 'react-i18next';
import { translations } from 'locales/i18n';

// validation schema
import { registrationSchema } from './registrationSchema';

// types
import { InputProps } from '../../../atoms/Input/types';
import { IRegistrationFormProps } from './types';
import {
  ButtonGroups,
  ButtonSizes,
  ButtonTypes,
} from '../../../atoms/Button/types';

// components
import Button from '../../../atoms/Button';

// styles
import {
  InputBox,
  StyledInput,
  ButtonContainer,
  Text,
  TriggerLink,
} from '../styles';
import { useSelector } from 'react-redux';
import { selectError } from '../../../store/signUpByPhone/selectors';
import { ErrorText, ErrorTextLink } from '../../../atoms/ErrorText';

// result login form
export const RegistrationForm = ({
  submit,
  loading,
}: IRegistrationFormProps) => {
  const { t } = useTranslation();
  const formElementsArray: InputProps[] = [];
  const [formError, setFormError] = useState(false);

  //  selector
  const isHasError = useSelector(selectError);

  useEffect(() => {
    if (isHasError) {
      setFormError(true);
    }
  }, [isHasError]);

  Object.keys(fieldsList).forEach(key => {
    formElementsArray.push(fieldsList[key]);
  });

  const interpolatePolicy = (
    <TriggerLink to={'/'}>
      {t(translations.signUpPage.actions.policy)}
    </TriggerLink>
  );

  return (
    <>
      <Formik
        initialValues={{ phone: '', password: '' }}
        validationSchema={registrationSchema}
        onSubmit={(values, actions) => {
          setFormError(false);
          submit(values);
        }}
      >
        {({ values, errors }) => (
          <Form>
            <InputBox>
              {formElementsArray.map((item, index) => (
                <StyledInput {...item} key={index} />
              ))}
            </InputBox>

            {formError && (
              <ErrorText>
                Користувач з таким номером телефоном вже зареєстрований.
                Скористайтесь функцією відновити пароль.{' '}
                <ErrorTextLink to={'/reset-password-by-email'}>
                  Забули пароль?
                </ErrorTextLink>
              </ErrorText>
            )}

            <ButtonContainer>
              <Button
                text={t(translations.signUpPagePopUp.actions.signUp)}
                type={ButtonTypes.Submit}
                group={ButtonGroups.Primary}
                size={ButtonSizes.Large}
                loading={loading}
              />
            </ButtonContainer>
          </Form>
        )}
      </Formik>
      <Text>
        <Trans i18nKey={translations.signUpPage.actions.policyTitle}>
          {t(translations.signUpPage.actions.policyTitle)}
          <a
            href={process.env.PUBLIC_URL + '/rules.pdf'}
            target="_blank"
            rel="noreferrer"
          >
            {interpolatePolicy}{' '}
          </a>
        </Trans>
      </Text>
    </>
  );
};

export default RegistrationForm;
